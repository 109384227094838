import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import TransmitWebSDKEnterpriseAuth from 'syf-transmit-bank/dist/TransmitWebSDKEnterpriseAuth';
import { destroy, formValueSelector } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';
import SendOtp from '../../components/transmit/sendOtp';
import VerifyOtp from '../../components/transmit/verifyOtp';
import TrustScoreUI from '../../components/transmit/trustScoreUI';
import data from '../../vendor/transmit/consumerBankApp';
import { NAO_FORM_ID } from '../../utilities/accountOpeningFlowType';
import type { ReduxState } from '../../reducers/index';
import { ACCESS_TOKEN_STORAGE_KEY, getAccessToken } from '../../utilities/authentication';
import {
  PERSONAL_INFORMATION_SECTION_ID,
  PRODUCT_SECTION_ID,
  JOINT_OWNERS_SECTION_ID,
  JOINT_OWNERS_LISTS_ID,
} from '../../form.constants';
import { useEffectOnce } from '../../utilities/reactHooks';
import PageWithCard from '../../components/page/pageWithCard';
import { getBlackBox } from '../authenticate/blackBox.service';
import { setFlashMessage } from '../../components/flashMessage/flashMessage.reducer';
import {
  FlashMessageVariant,
  FlashMessageText,
} from '../../components/flashMessage/flashMessage.constants';
import { TRANSMIT_RESPONSE, MFA_STATUS } from './otp.constants';
import Routes from '../routes/routes.constants';
import {
  buildTransmitPrimaryApplicationInfo,
  buildTransmitJointOwner,
} from './InitiateTransmitOtp.utilities';
import useOtpStyles from './otp.styles';
import i18n from '../../strings/i18n';
import type { NewCustomerApplicationForm } from '../newAccountOpening/applications.actions';
import {
  updateTransmitResponse,
  submitNewUserTransmitApplication,
} from '../newAccountOpening/applications.actions';
import type { SubmitApplicationClientResponse } from '../../utilities/types';
import { reportDynamicYieldEvent } from '../../services/dynamicYield.service';
import { EventNames } from '../../components/cms/dynamicYield.constants';
import {
  ACTION_SET_LOADING_FALSE,
  ACTION_SET_TRACKING_ID,
} from '../newAccountOpening/applications.reducer';
import { FormHeaderProps } from '../../components/formWithImage/typings';
import {
  ANALYTICS_INITIATE_OTP_TRANSMIT,
  ANALYTICS_INITIATE_OTP_TRANSMIT_COMPLETE,
} from '../../analytics/actions';

type StateProps = {
  products: Record<string, string>;
  personalInformationFields?: Record<string, string>;
  jointOwnersList?: Record<string, string>;
  formData: NewCustomerApplicationForm;
  affinityCompanyCode: string;
};

const mapStateToProps = (state: ReduxState): StateProps => {
  const selector = formValueSelector(NAO_FORM_ID);
  const { applicationForm } = state;
  const { formNAOData } = applicationForm;
  return {
    products: selector(state, PRODUCT_SECTION_ID),
    personalInformationFields: selector(state, PERSONAL_INFORMATION_SECTION_ID),
    jointOwnersList: selector(state, `${JOINT_OWNERS_SECTION_ID}.${JOINT_OWNERS_LISTS_ID}`),
    affinityCompanyCode: state.products.affinityCompanyCode,
    formData: formNAOData,
  };
};

type DispatchProps = {
  setErrorFlashMessage: (arg1: string) => void;
  updateTransmitStatus: (transmitResponse: Record<string, string>) => void;
  submitTransmitApplication: (
    formData: NewCustomerApplicationForm,
    affinityCompanyCode: string,
    aoFormId: string
  ) => void;
  setErrorTransmit: () => void;
  recordAnalyticsPageView: () => void;
  recordAnalyticsComplete: () => void;
};

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<null, null, AnyAction>
): DispatchProps => ({
  setErrorFlashMessage: (messageText: string) =>
    dispatch(
      setFlashMessage({
        messageType: FlashMessageVariant.ERROR,
        messageText,
      })
    ),
  updateTransmitStatus: (transmitResponse: Record<string, string>) =>
    dispatch(updateTransmitResponse(transmitResponse)),
  setErrorTransmit: () => dispatch(push(Routes.PROCESSING_APPLICATION_TRANSMIT)),
  submitTransmitApplication: (
    formData: NewCustomerApplicationForm,
    affinityCompanyCode: string,
    aoFormId: string
  ) => {
    dispatch(submitNewUserTransmitApplication({ formData, affinityCompanyCode, aoFormId })).then(
      (payload: SubmitApplicationClientResponse) => {
        if (payload) {
          const { transmitResponse, status } = payload;
          if (status) {
            reportDynamicYieldEvent(EventNames.NAO_APPLICATION_COMPLETE, {
              status: payload.status,
            });
          }
          if (transmitResponse.status.responseCode === TRANSMIT_RESPONSE.STATUS_CANCELLED) {
            dispatch(push(Routes.CANCEL_APPLICATION));
          }
        }
        dispatch(push(Routes.PROCESSING_APPLICATION_TRANSMIT));
        dispatch(destroy(NAO_FORM_ID));
        dispatch({ type: ACTION_SET_TRACKING_ID, payload: '' });
        dispatch({ type: ACTION_SET_LOADING_FALSE });
      }
    );
  },
  recordAnalyticsPageView: () => dispatch({ type: ANALYTICS_INITIATE_OTP_TRANSMIT }),
  recordAnalyticsComplete: () => dispatch({ type: ANALYTICS_INITIATE_OTP_TRANSMIT_COMPLETE }),
});

type AllProps = StateProps & DispatchProps & FormHeaderProps;

const config = JSON.stringify(data);

const customUI = {
  SendOtp,
  VerifyOtp,
  trustScoreUI: TrustScoreUI,
};

const InitiateNewUserTransmitOtp = (props: AllProps) => {
  const {
    personalInformationFields,
    jointOwnersList,
    products,
    setErrorFlashMessage,
    setErrorTransmit,
    updateTransmitStatus,
    affinityCompanyCode,
    formData,
    submitTransmitApplication,
    recordAnalyticsPageView,
    recordAnalyticsComplete,
  } = props;
  const classes = useOtpStyles(props);
  const [authToken, setAuthToken] = useState('');
  const [hasValidatedOTP, setHasValidatedOTP] = useState(false);
  const history = useHistory();

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.history.pushState(null, null, window.location.pathname);
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    if (
      personalInformationFields?.firstName === undefined &&
      personalInformationFields?.socialSecurityNumber === undefined
    ) {
      history.replace(Routes.NAO);
    }
  }, [personalInformationFields]);

  const handleAuthResponse = (response) => {
    const checkCurrentPath = history.location.pathname.includes(Routes.NAO);

    if (!hasValidatedOTP) {
      try {
        if (!checkCurrentPath) updateTransmitStatus(response);
      } catch (err) {
        setErrorFlashMessage(FlashMessageText.GENERIC_ERROR);
        setErrorTransmit();
        return {};
      }

      if (
        response.status.responseCode === TRANSMIT_RESPONSE.STATUS_SUCCESS &&
        response.status.responseDesc === i18n({ ResponseDescription: 'SUCCESS' }) &&
        response.mfaStatus === MFA_STATUS.STATUS_PASS
      ) {
        setHasValidatedOTP(true);
        recordAnalyticsComplete();
      } else {
        if (response.applicationId && response.applicantId) {
          if (!checkCurrentPath)
            submitTransmitApplication(formData, affinityCompanyCode, NAO_FORM_ID);
        } else {
          setErrorTransmit();
        }
        return {};
      }
    }

    history.replace(`${Routes.NAO}/6`);
    return response;
  };

  useEffectOnce(() => {
    const jwtToken1 = getAccessToken(ACCESS_TOKEN_STORAGE_KEY);
    setAuthToken(jwtToken1);
    recordAnalyticsPageView();
  });

  let primaryCustomerInfo;
  let jointOwnerInfo;
  let jointOwnerParam;
  let params;

  if (personalInformationFields?.firstName) {
    primaryCustomerInfo = buildTransmitPrimaryApplicationInfo(personalInformationFields);
    jointOwnerInfo = buildTransmitJointOwner(jointOwnersList);
    jointOwnerParam = jointOwnerInfo ? jointOwnerInfo[0] : '';

    params = {
      header: {
        trans_id: uuidv4(),
        client_id: window.__config__.CLIENT_ID,
        channel_id: i18n({ TransmitStaticParams: 'CHANNEL' }),
      },
      applicant_info: [
        {
          ...primaryCustomerInfo,
        },
        ...jointOwnerParam,
      ],
      account_type: products?.ownerType,
      product_name: products?.productType,
      user_id: uuidv4(),
      appEnv: i18n({ TransmitStaticParams: 'APPENV' }),
      language_code: i18n({ TransmitStaticParams: 'LANGUAGE' }),
      jwt_token: authToken,
      black_box: getBlackBox(),
      branding: {},
    };
  }

  return (
    <PageWithCard description="">
      <div className={classes.contentHolder}>
        {authToken && params && (
          <TransmitWebSDKEnterpriseAuth
            config={config}
            handleAuthResponse={handleAuthResponse}
            customUI={customUI}
            params={params}
          />
        )}
      </div>
    </PageWithCard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InitiateNewUserTransmitOtp);
