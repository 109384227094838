/* eslint-disable no-underscore-dangle */

import type { ReduxState } from '../reducers';
import { SecurityEligibilityFlows } from '../containers/security/security.service';
import pageTrack from './pageAnalytics.constants';

export const getNewTransferAttr = (state: ReduxState) => {
  if (state.newTransfer.transfer && state.newTransfer.transfer.frequency) {
    return state.newTransfer.transfer.frequency === 'ONCE' ? 'onetime' : 'recurring';
  }
  return '';
};

export const isNAOOTPFlow = (state: ReduxState) => {
  const { securityEligibilityFlow } = state.profile;
  return (
    securityEligibilityFlow &&
    securityEligibilityFlow === SecurityEligibilityFlows.NEW_ACCOUNT_OPENING_WITH_OTP
  );
};

export const isNAOTransmitFlow = (state: ReduxState) => {
  return state.authenticate.isLoggedIn
    ? pageTrack.pagesubfunction.EAO
    : pageTrack.pagesubfunction.NAO;
};

export const getPageFunctionTypeForOTP = (state: ReduxState) => {
  const { securityEligibilityFlow } = state.profile;
  switch (securityEligibilityFlow) {
    case SecurityEligibilityFlows.USERNAME_OTP_REQUIRED:
      return pageTrack.pagefunction.change_username;

    case SecurityEligibilityFlows.PASSWORD_OTP_REQUIRED:
      return pageTrack.pagefunction.change_password;

    case SecurityEligibilityFlows.NEW_ACCOUNT_OPENING_WITH_OTP:
    default:
      return pageTrack.pagefunction.ao_otp;
  }
};

/** Wait for the utag.js script from analytics team to load
 * @param timeout max time (ms) to wait for utag script. Set to 0 for no timeout.
 */
export const waitForUtag = (timeout = 3000) => {
  return new Promise((resolve) => {
    if (window.syfedltagging) {
      // if present immediately resolve
      resolve(null);
    } else {
      const intervalId = setInterval(() => {
        // utag sets the following window object on initialization
        if (window.syfedltagging) {
          clearInterval(intervalId);
          resolve(null);
        }
      }, 100); // Check every 100 milliseconds for availability

      // Set max time we want to wait for utag script
      if (timeout > 0) {
        setTimeout(() => {
          clearInterval(intervalId);
          resolve(null);
        }, timeout);
      }
    }
  });
};

export default getNewTransferAttr;
