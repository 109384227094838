import pageAnalytics from '../pageAnalytics';
import pageTrack from '../pageAnalytics.constants';
import type { ProcessingApplicationAnalyticsTransmitAction } from '../actions';
import { ANALYTICS_PROCESSING_APPLICATION_TRANSMIT } from '../actions';
import { TRANSMIT_RESPONSE, TRANSMIT_ANALYTICS_RESPONSE } from '../../containers/otp/otp.constants';

const viewPage = (action: ProcessingApplicationAnalyticsTransmitAction) => {
  const responseStatus = action.ctaStatus;
  let pageName;
  let transmitStatus;

  switch (responseStatus) {
    case TRANSMIT_RESPONSE.STATUS_SYSTEM_ERROR:
      transmitStatus = TRANSMIT_ANALYTICS_RESPONSE.STATUS_SYSTEM_ERROR;
      pageName = pageTrack.pagename.complete_no_otp;
      break;
    case TRANSMIT_RESPONSE.STATUS_CANCELLED:
      transmitStatus = TRANSMIT_ANALYTICS_RESPONSE.STATUS_CANCELLED;
      pageName = pageTrack.pagename.complete_no_otp;
      break;
    case TRANSMIT_RESPONSE.STATUS_AUTH_FAILURE:
      transmitStatus = TRANSMIT_ANALYTICS_RESPONSE.STATUS_AUTH_FAILURE;
      pageName = pageTrack.pagename.complete_no_otp;
      break;
    case TRANSMIT_RESPONSE.STATUS_FRAUD_DEVICE:
      transmitStatus = TRANSMIT_ANALYTICS_RESPONSE.STATUS_FRAUD_DEVICE;
      pageName = pageTrack.pagename.denied;
      break;
    case TRANSMIT_RESPONSE.STATUS_SESSION_EXPIRED:
      transmitStatus = TRANSMIT_ANALYTICS_RESPONSE.STATUS_SESSION_EXPIRED;
      pageName = pageTrack.pagename.start_error;
      break;
    case TRANSMIT_RESPONSE.STATUS_SOMETHING_WRONG:
      transmitStatus = TRANSMIT_ANALYTICS_RESPONSE.STATUS_SOMETHING_WRONG;
      pageName = pageTrack.pagename.start_error;
      break;
    case TRANSMIT_RESPONSE.STATUS_INVALID_BSL_TOKEN:
      transmitStatus = TRANSMIT_ANALYTICS_RESPONSE.STATUS_INVALID_BSL_TOKEN;
      pageName = pageTrack.pagename.start_error;
      break;
    case TRANSMIT_RESPONSE.STATUS_REVIEWED:
      transmitStatus = TRANSMIT_ANALYTICS_RESPONSE.STATUS_REVIEWED;
      pageName = pageTrack.pagename.pending;
      break;
    case TRANSMIT_RESPONSE.STATUS_DECLINED:
      transmitStatus = TRANSMIT_ANALYTICS_RESPONSE.STATUS_DECLINED;
      pageName = pageTrack.pagename.denied;
      break;
    case TRANSMIT_RESPONSE.STATUS_SUCCESS:
      transmitStatus = TRANSMIT_ANALYTICS_RESPONSE.STATUS_SUCCESS;
      pageName = pageTrack.pagename.start_error;
      break;
    default:
      transmitStatus = '';
      pageName = pageTrack.pagename.start_error;
  }

  pageAnalytics
    .newPageView({
      account_type: 'generic',
      pagekind: 'account_opening',
      pagefunction: pageTrack.pagefunction.review,
      pagesubfunction: action.isNewAccountOpening
        ? pageTrack.pagesubfunction.NAO
        : pageTrack.pagesubfunction.EAO,
      pagename: pageName,
      attr: '',
      transmitStatus,
    })
    .record();
};

export const processingApplicationTransmitPageEvents = {
  [ANALYTICS_PROCESSING_APPLICATION_TRANSMIT]: viewPage,
} as const;

export default processingApplicationTransmitPageEvents;
