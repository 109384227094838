import React, { useEffect, useState } from 'react';
import { push, replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { destroy, formValueSelector } from 'redux-form';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import TransmitWebSDKEnterpriseAuth from 'syf-transmit-bank/dist/TransmitWebSDKEnterpriseAuth';
import { useHistory } from 'react-router-dom';
import {
  EAO_FORM_ID,
  JOINT_OWNERS_LISTS_ID,
  JOINT_OWNERS_SECTION_ID,
  PRODUCT_SECTION_ID,
} from '../../form.constants';
import SendOtp from '../../components/transmit/sendOtp';
import VerifyOtp from '../../components/transmit/verifyOtp';
import TrustScoreUI from '../../components/transmit/trustScoreUI';
import data from '../../vendor/transmit/consumerBankApp';
import { getBlackBox } from '../authenticate/blackBox.service';
import type { ReduxState } from '../../reducers';
import { ACCESS_TOKEN_STORAGE_KEY, getAccessToken } from '../../utilities/authentication';
import { buildEmptyCustomerInfoState } from '../../utilities/tests/applicationState.factory';
import { CustomerInfo, SubmitApplicationClientResponse } from '../../utilities/types';
import useEffectOnce from '../../utilities/reactHooks';
import { TransmitJointOwnersLists, TRANSMIT_RESPONSE } from './otp.constants';
import {
  buildTransmitNewJointOwnerInfo,
  buildTransmitPrimaryCustomerInfo,
  buildTransmitSelectedJointOwnerInfo,
} from './InitiateTransmitOtp.utilities';
import i18n from '../../strings/i18n';
import {
  FlashMessageText,
  FlashMessageVariant,
} from '../../components/flashMessage/flashMessage.constants';
import PageWithCard from '../../components/page/pageWithCard';
import useOtpStyles from './otp.styles';
import {
  ExistingCustomerApplicationForm,
  NewCustomerApplicationForm,
  submitNewUserTransmitApplication,
  updateTransmitResponse,
} from '../newAccountOpening/applications.actions';
import { ApplicationStatuses } from '../newAccountOpening/newAccountOpening.constants';
import Routes from '../routes/routes.constants';
import {
  ACTION_CLEAR_PENDING_ACCOUNT_APPLICATION,
  ACTION_SET_TRACKING_ID,
} from '../newAccountOpening/applications.reducer';
import { ACTION_SET_LOADING_FALSE } from '../authenticate/authenticate.reducer';
import { setFlashMessage } from '../../components/flashMessage/flashMessage.reducer';
import { FormHeaderProps } from '../../components/formWithImage/typings';
import {
  ANALYTICS_INITIATE_OTP_TRANSMIT,
  ANALYTICS_INITIATE_OTP_TRANSMIT_COMPLETE,
} from '../../analytics/actions';

type StateProps = {
  customerInfo: CustomerInfo;
  products: Record<string, string>;
  noPhoneNumber: boolean;
  ownerType: string;
  jointOwnersList: TransmitJointOwnersLists;
  formData: ExistingCustomerApplicationForm;
  affinityCompanyCode: string;
};

type DispatchProps = {
  setErrorFlashMessage: (arg1: string) => void;
  updateTransmitStatus: (transmitResponse: Record<string, string>) => void;
  submitTransmitApplication: (
    formData: ExistingCustomerApplicationForm,
    affinityCompanyCode: string,
    aoFormId: string
  ) => void;
  setErrorTransmit: () => void;
  recordAnalyticsPageView: () => void;
};

export type TransmitInitiateOtpEAOAllProps = StateProps & DispatchProps & FormHeaderProps;

const mapStateToProps = (state: ReduxState): StateProps => {
  const selector = formValueSelector(EAO_FORM_ID);
  const { applications } = state;
  const { applicationForm } = state;
  const { customerInfo = buildEmptyCustomerInfoState() } = applications;
  const { formData } = applicationForm;
  const { ownerType } = selector(state, PRODUCT_SECTION_ID) || {};
  const noPhoneNumber = selector(state, 'noPhoneNumber');
  const jointOwnersList = selector(state, `${JOINT_OWNERS_SECTION_ID}.${JOINT_OWNERS_LISTS_ID}`);
  return {
    customerInfo,
    products: selector(state, PRODUCT_SECTION_ID),
    noPhoneNumber,
    ownerType,
    jointOwnersList,
    formData,
    affinityCompanyCode: state.products.affinityCompanyCode,
  };
};

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<null, null, AnyAction>
): DispatchProps => ({
  setErrorFlashMessage: (messageText: string) =>
    dispatch(
      setFlashMessage({
        messageType: FlashMessageVariant.ERROR,
        messageText,
      })
    ),
  updateTransmitStatus: (transmitResponse: Record<string, string>) =>
    dispatch(updateTransmitResponse(transmitResponse)),
  setErrorTransmit: () => dispatch(push(Routes.PROCESSING_APPLICATION_TRANSMIT)),
  submitTransmitApplication: (
    formData: NewCustomerApplicationForm,
    affinityCompanyCode: string,
    aoFormId: string
  ) => {
    dispatch(submitNewUserTransmitApplication({ formData, affinityCompanyCode, aoFormId })).then(
      (payload: SubmitApplicationClientResponse) => {
        if (payload?.status === ApplicationStatuses.CREATED) {
          dispatch(replace(Routes.EAO_ACCOUNT_CONFIRMATION));
          dispatch({ type: ACTION_CLEAR_PENDING_ACCOUNT_APPLICATION });
        } else {
          dispatch(push(Routes.PROCESSING_APPLICATION_TRANSMIT));
        }
        if (payload?.status !== ApplicationStatuses.OTP) {
          dispatch({ type: ACTION_SET_LOADING_FALSE });
        }
        dispatch({ type: ACTION_SET_TRACKING_ID, payload: '' });
        dispatch(destroy(EAO_FORM_ID));
        dispatch({ type: ACTION_SET_LOADING_FALSE });
      }
    );
  },
  recordAnalyticsPageView: () => dispatch({ type: ANALYTICS_INITIATE_OTP_TRANSMIT }),
});

const config = JSON.stringify(data);

const customUI = {
  SendOtp,
  VerifyOtp,
  trustScoreUI: TrustScoreUI,
};

const InitiateExistingUserTransmitOtp = (props: TransmitInitiateOtpEAOAllProps) => {
  const {
    customerInfo,
    products,
    noPhoneNumber,
    jointOwnersList,
    formData,
    affinityCompanyCode,
    setErrorFlashMessage,
    updateTransmitStatus,
    submitTransmitApplication,
    setErrorTransmit,
    recordAnalyticsPageView,
  } = props;

  const classes = useOtpStyles(props);
  const [authToken, setAuthToken] = useState('');
  const history = useHistory();

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.history.pushState(null, null, window.location.pathname);
  };

  useEffect(() => {
    window.onbeforeunload = () => true;
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (customerInfo?.name?.firstName === '' && customerInfo?.taxId === '') {
      history.replace(Routes.EAO);
    }
  }, [customerInfo]);

  useEffectOnce(() => {
    const jwtToken1 = getAccessToken(ACCESS_TOKEN_STORAGE_KEY);
    setAuthToken(jwtToken1);
    recordAnalyticsPageView();
  });

  const handleAuthResponse = (response) => {
    const checkCurrentPath = history.location.pathname.includes(Routes.EAO);

    if (response) {
      try {
        if (!checkCurrentPath) updateTransmitStatus(response);
      } catch (err) {
        setErrorFlashMessage(FlashMessageText.GENERIC_ERROR);
        setErrorTransmit();
        return {};
      }
      if (
        response.applicationId &&
        response.applicantId &&
        response.mfaStatus === i18n({ ResponseDescription: 'EAO_MFA_STATUS' })
      ) {
        if (!checkCurrentPath)
          submitTransmitApplication(formData, affinityCompanyCode, EAO_FORM_ID);
      } else {
        setErrorTransmit();
      }
      return {};
    }
    return response;
  };

  const buildTransmitJointOwner = () => {
    const jointOwnerArray = [];
    if (jointOwnersList?.selectedExistingJointOwners.length) {
      jointOwnerArray.push(
        jointOwnersList?.selectedExistingJointOwners.map((existingJointOwner) => {
          return buildTransmitSelectedJointOwnerInfo(existingJointOwner);
        })
      );
    }
    if (jointOwnersList?.newJointOwners.length) {
      jointOwnerArray.push(
        jointOwnersList?.newJointOwners.map((newJointOwner) => {
          return buildTransmitNewJointOwnerInfo(newJointOwner, noPhoneNumber);
        })
      );
    }
    return jointOwnerArray;
  };

  const primaryCustomerInfo = buildTransmitPrimaryCustomerInfo(customerInfo);
  const jointOwnerInfo = buildTransmitJointOwner();
  const jointOwnerList = jointOwnerInfo.length
    ? jointOwnerInfo.reduce((jointOwner, value) => jointOwner.concat(value), [])
    : '';

  const params = {
    header: {
      trans_id: uuidv4(),
      client_id: window.__config__.CLIENT_ID,
      channel_id: i18n({ TransmitStaticParams: 'CHANNEL' }),
    },
    applicant_info: [
      {
        ...primaryCustomerInfo,
      },
      ...jointOwnerList,
    ],
    account_type: products?.ownerType,
    product_name: products?.productType,
    user_id: uuidv4(),
    appEnv: i18n({ TransmitStaticParams: 'APPENV' }),
    language_code: i18n({ TransmitStaticParams: 'LANGUAGE' }),
    jwt_token: authToken,
    black_box: getBlackBox(),
    branding: {},
  };

  return (
    <PageWithCard description="">
      <div className={classes.contentHolder}>
        {authToken && (
          <TransmitWebSDKEnterpriseAuth
            config={config}
            handleAuthResponse={handleAuthResponse}
            customUI={customUI}
            params={params}
          />
        )}
      </div>
    </PageWithCard>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InitiateExistingUserTransmitOtp);
