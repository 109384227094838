import React from 'react';
import { Grid } from '@material-ui/core';
import i18n from '../../strings/i18n';

type BackButtonWarningProps = {
  readonly warningClass: string;
};

export default function BackButtonWarning({ warningClass }: BackButtonWarningProps) {
  return (
    <Grid item data-test="back-button-warning" component="div" xs={12} className={warningClass}>
      {i18n({ OTPText: 'BACK_BUTTON_WARNING_TEXT' })}
    </Grid>
  );
}
